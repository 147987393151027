import { Container,
	Input,
	Button,
	InputAdornment,
	IconButton,
	FormControl,
	InputLabel,
	Alert
} from "@mui/material";
import { useState } from "react";
import logo from '../logo.png'
import { post, put } from "../components/Functions";
import { useParams, useSearchParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function ResetPassword(props) {
	const [email2, setEmail2] = useState(null)
	const [pwd, setPwd] = useState(null)
	const [pwd2, setPwd2] = useState(null)
    const [showPwd, setShowPwd] = useState(false)
    const [error, setError] = useState(null)
    
	const minLength = 8
	
	let { email } = useParams();	
	const [params] = useSearchParams();
	
	const handleResetPassword = () => {
		post("/user/api/v2/user/password/reset" , {email: email2})
		.then(res => {
			if (!res.isError) {
				alert(res.data)
			} else {
				alert(res.error)
			}
		})
	}
	
	const handlePutResetPwd = () => {
		let	token = params.get("token")
		
		if (!pwd || pwd.length < minLength) {
			setError("Your password must contain at least 8 characters.")
		} else if (pwd===pwd2) {
			put("/user/api/v2/user/password/" + email + "?token=" + token, {password: pwd})
			.then(res => {
				if (!res.isError) {
					alert(res.data)
					setError(null)
				} else {
					setError(res.error)
				}
			})
		} else {
			setError("Your passwords do not match!")
		}
		
	}
	
	if (email==="reset") {

		return (
		
		<Container sx={{position: "fixed", 
					top: "30%", 
					transform: "translate(-50%,-30%)",
					left: "50%",
					width: "100%",
					maxWidth: "400px"}}>
			<div align="center"><img src={logo} atl='logo' width={80}/></div>
			Enter the email address associated with your account and begin the password reset process.
			
			<p><strong>Email Address</strong></p>
			<Input
				fullWidth={true}
				placeholder="your.email@domain.com"
				type="text"
				onChange={(e) => setEmail2(e.target.value.toLowerCase())}
				/>
				
			<Button variant="contained" 
				fullWidth={true}
				sx={{ marginTop: "20px" }}
				onClick={() => handleResetPassword()}
				>
				Reset Password
			</Button>
		</Container>
		)
	} else {
	
		return (
		
		<Container sx={{position: "fixed", 
					top: "30%", 
					transform: "translate(-50%,-30%)",
					left: "50%",
					width: "100%",
					maxWidth: "400px"}}>
			<div align="center"><img src={logo} atl='logo' width={80}/></div>
			<FormControl fullWidth variant="standard">
            
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
			
			<Input
				fullWidth={true}
				placeholder="Password"
				type={showPwd ? "text" : "password"}
				onChange={(e) => setPwd(e.target.value)}
				endAdornment={
					<InputAdornment position="end" edge="end">
					
					<IconButton onClick={() => setShowPwd(!showPwd)}>
					
						{showPwd
							? <VisibilityIcon />
							: <VisibilityOffIcon />
						}
					</IconButton>
					</InputAdornment>
					}
				/>
			</FormControl>
			
			<FormControl fullWidth variant="standard">
            
            <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
			
			<Input
				fullWidth={true}
				placeholder="Password"
				type={showPwd ? "text" : "password"}
				onChange={(e) => setPwd2(e.target.value)}
				endAdornment={
					<InputAdornment position="end" edge="end">
					
					<IconButton onClick={() => setShowPwd(!showPwd)}>
					
						{showPwd
							? <VisibilityIcon />
							: <VisibilityOffIcon />
						}
					</IconButton>
					</InputAdornment>
					}
				/>
			</FormControl>
				
			{error
				? <Alert severity="error">{error}</Alert>
				: <></>
			}
			<Button variant="contained" 
				fullWidth={true}
				sx={{ marginTop: "20px" }}
				onClick={() => handlePutResetPwd()}
				>
				Change Password
			</Button>
		</Container>
		)
		
	}
}

export default ResetPassword;
