function get(url, token=null) {
	return fetch(url, {
		method: "get",
		headers: token ? {"X-AIDATAME-ACCESS-TOKEN": token, 
						'Content-Type':'application/json'} 
						: {'Content-Type':'application/json'},
	}).then(res => {
		let status = res.status
		
		if (status >= 400) {
			return res.json().then(res => {
				return {
					isError: true,
					error: res.message,
					status: status
				}
			})
		} else {
			return res.json().then(res => {
				return {
					isError: false,
					data: res,
					status: status
				}
			})
			.catch(error => {
				return {
					isError: true,
					error: error,
					status: status
				}
			})
		}
	})
}

function post(url, data=null, token=null) {
	return fetch(url, {
		method: "post",
		headers: token ? {"X-AIDATAME-ACCESS-TOKEN": token, 
						'Content-Type':'application/json'} 
						: {'Content-Type':'application/json'},
		body: JSON.stringify(data)
	}).then(res => {
		let status = res.status
		
		if (res.status >= 400) {
			return res.json().then(res => {
				return {
					isError: true,
					error: res.message,
					status: status
				}
			})
		} else {
			return res.json().then(res => {
				return {
					isError: false,
					data: res,
					status: status
				}
			})
		}
	})
}

function put(url, data=null, token=null) {
	return fetch(url, {
		method: "put",
		headers: token ? {"X-AIDATAME-ACCESS-TOKEN": token, 
						'Content-Type':'application/json'} 
						: {'Content-Type':'application/json'},
		body: JSON.stringify(data)
	}).then(res => {
		let status = res.status
		if (res.status >= 400) {
			return res.json().then(res => {
				return {
					isError: true,
					error: res.message,
					status: status
				}
			})
		} else {
			return res.json().then(res => {
				return {
					isError: false,
					data: res,
					status: status
				}
			})
		}
	})
}

function del(url, data=null, token=null) {
	return fetch(url, {
		method: "delete",
		headers: token ? {"X-AIDATAME-ACCESS-TOKEN": token, 
						'Content-Type':'application/json'} 
						: {'Content-Type':'application/json'},
		body: JSON.stringify(data)
	}).then(res => {
		let status = res.status
		if (res.status >= 400) {
			return res.json().then(res => {
				return {
					isError: true,
					error: res.message,
					status: status
				}
			})
		} else {
			return res.json().then(res => {
				return {
					isError: false,
					data: res,
					status: status
				}
			})
		}
	})
}

export { get, post, put, del }

