import { useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Menu,
  MenuItem,
  IconButton
} from '@mui/material'
import dayjs from "dayjs"

export default function TimeSettingTool({defaultRange, onItemClick, ...props}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [range, setRange] = useState(defaultRange ? defaultRange : '7D')
  
  
  const handleRange = (range, interval) => {
    const finish = dayjs()
    
    if (range==='24H') {
      const start = finish.subtract(1, "day")
	    onItemClick(interval, start, finish, range)
    } else if (range==='7D') {
      const start = finish.subtract(7, "day")
	    onItemClick(interval, start, finish, range)
    } else if (range==='3M') {
      const start = finish.subtract(3, "month")
	    onItemClick(interval, start, finish, range)
    } else if (range==='1Y') {
      const start = finish.subtract(1, "year")
	    onItemClick(interval, start, finish, range)
    } else {
      const start = dayjs("2015-01-01")
	    onItemClick(interval, start, finish, range)
    }
    setRange(range)
    setAnchorEl(null)
  }
  
  return (
  	<>
		  <IconButton 
		  	onClick={(e) => setAnchorEl(e.currentTarget)}
		  	tag='span'
		  	>
		  	<SettingsIcon fontSize="small"/>
			</IconButton>
			
			<Menu
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
				>
				<MenuItem
					onClick={(e) => handleRange('24H', '1H')}
					>24H
			</MenuItem>
				<MenuItem
					onClick={(e) => handleRange('7D', '1D')}
					>7D
			</MenuItem>
				<MenuItem
					onClick={(e) => handleRange('3M', '1D')}
					>3M
			</MenuItem>
				<MenuItem
					onClick={(e) => handleRange('1Y', '1M')}
					>1Y
			</MenuItem>
				<MenuItem
					onClick={(e) => handleRange('All', '1M')}
					>All
			</MenuItem>
			</Menu>
  	
  	</>	
  )
}
