import {
    LinearProgress,
} from "@mui/material"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { get } from "./Functions"
import CardActions from "./CardActions"
import Score from "./Table/Score"

import Cookies from "js-cookie";


export default function ScoreTable(
	{
		portfolios, 
		pendingChange = (v,i) => { return v },
		onFetch,
		defaultPortfolio,
		...props
		}) {
    
	const oneHourToMilliSeconds = 360000;
	let navigate = useNavigate();
	
	const [items, setItems] = useState([]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	
	const [score, setScore] = useState("ESG Score")
	const [scoreOptions, setScoreOptions] = useState(["ESG Score","SDG Score"])
	const [range, setRange] = useState(null)
	const [portfolio, setPortfolio] = useState(null)
	
	const [interval, setInterval] = useState(null)
	const [start, setStart] = useState(null)
	const [finish, setFinish] = useState(null)
	
	const handleOnFetch = (i,s,f,r,p) => {
		setIsLoaded(false)
		
		onFetch(i,s,f,r,p)
		.then(res => {
			if (!res.isError) {
				setItems(res.data)
				setIsLoaded(true)
				setRange(r)
				setStart(s)
				setFinish(f)
				setInterval(i)
				//setPortfolio(p)
				setError(null)
			} else {
				setError(res.error)
				setItems(res.data)
			}
		})
	}
	

	
	const handlePortfolioChange = (v,i) => {
		Promise.resolve(pendingChange(v,i))
		.then((res) => {
			handleOnFetch(interval,start,finish,range,res)
			setPortfolio(res)
		}, {})
		
	}
	
    
  useEffect(() => {
		const now = new Date()
		const finish = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
		var start = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
		start.setMonth(start.getMonth() - 3)

		handleOnFetch("1D", start, finish, "3M", defaultPortfolio)
		
  }, [portfolios.values])

	return (
    <>
    <CardActions 
				options={{
					score: {values: scoreOptions, visible: true },
					portfolios: portfolios
				}}
				onScoreChange={setScore}
				onTimeChange={(i,s,f,r) => handleOnFetch(i,s,f,r,portfolio)}
				onPortfolioChange={handlePortfolioChange}
				{...props}
				/>
		{error
			? <p>{error.message}</p>
			: !isLoaded
				? <LinearProgress />
				: <Score items={items}
						label={range}
						index={score}
						
						/>
		}
	        
    </>
	)

    
    
}
