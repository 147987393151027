import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination,
    Button,
    IconButton,
    TextField,
    Modal,
    Paper,
    FormControlLabel,
    Switch,
    Checkbox,
    Box,
    Typography,
    Stack,
    LinearProgress
} from "@mui/material"
import { useEffect, useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { put, get, del } from "../components/Functions"
import Cookies from "js-cookie";


function EditUser({item, onChange, ...props}) {
    
  const [isOpen, setIsOpen] = useState(false)
  const [isAdmin, setIsAdmin] = useState(item.is_admin)
  const [authLevel, setAuthLevel] = useState(item.auth_lvl)
  
  const handleSubmit = (e) => {

		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
    setIsOpen(false)
    put("/user/api/v2/admin",
    	{email: item.email, auth_lvl: parseInt(authLevel), is_admin: isAdmin },
    	token
    ).then(res => {
    	if (!res.isError) {
    		onChange()
    		setIsOpen(false)
    	} else {
    		alert(res.error)
    	}
    })
  }
  
  
  return (
    <>
    <IconButton color="primary"
        onClick={(e) => setIsOpen(true)}
        >
        <EditIcon />
    </IconButton>
    
    <Modal open={isOpen}
    	onClose={() => setIsOpen(false)}
  		>
      <Paper variant="modal"
      	sx={{ pt: 2, pb: 2, pl: 3, pr: 3}}
      	>
      	<Typography variant="h6" sx={{ mb: 2 }}>
      	{item.email}
      	</Typography>
      	<TextField type="number"
      		value={authLevel}
      		onChange={(e) => setAuthLevel(e.target.value)}
      		label="Authorization level"
      		variant="standard"
      		fullWidth={true}
    			/>
  			<FormControlLabel
  				control={<Switch checked={isAdmin}
  										onChange={() => setIsAdmin(!isAdmin)}
  										/>
  				} 
  				label="Is Admin"
  				/>
				<Stack direction="row"
					justifyContent="flex-end"
					sx={{ mt: 2 }}
					spacing={0.5}
					>
				<Button variant="contained"
					onClick={() => handleSubmit()}
					>OK</Button>
				<Button variant="outlined"
					onClick={() => setIsOpen(false)}
					>Cancel</Button>
				</Stack>
      </Paper>
    </Modal>
    </>
  )
}

function DeleteUser({item, onChange, ...props}) {
    
  const [isOpen, setIsOpen] = useState(false)
  
  const handleSubmit = (e) => {
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		del("/user/api/v2/admin",
			{ email: item.email },
			token
		).then(res => {
			if (!res.isError) {
				onChange()
				setIsOpen(false)
			} else {
				alert(res.error)
			}
		})
  }
  
  return (
    <>
    <IconButton color="error"
    	onClick={() => setIsOpen(true)}
      >
      <DeleteIcon />
        
    </IconButton>
    <Modal open={isOpen}
    	onClose={() => setIsOpen(false)}
    	>
      <Paper variant="modal"
      	
      	sx={{ pt: 2, pb: 2, pl: 3, pr: 3}}
      	>
      	Are you sure you want to delete this user ?
				<Stack direction="row"
					justifyContent="flex-end"
					sx={{ mt: 2 }}
					spacing={0.5}
					>
				<Button variant="contained"
					onClick={() => handleSubmit()}
					>OK</Button>
				<Button variant="outlined"
					onClick={() => setIsOpen(false)}
					>Cancel</Button>
				</Stack>
      </Paper>
    </Modal>
    </>
  )
}




export default function AdminPage(props) {
    
  const user = JSON.parse(localStorage.getItem('user'))
	const [orderBy, setOrderBy] = useState(null);
	const [asc, setAsc] = useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	
	const [items, setItems] = useState([])
	const [error, setError] = useState(null)
	const [isLoaded, setIsLoaded] = useState(false)
	
	let token = Cookies.get("AIDATAME_AUTH_TOKEN")
	
	const handleChangeRowsPerPage = (e) => {
		setRowsPerPage(parseInt(e.target.value, 25));
  	setPage(0);
	}
	
  const compare = (a, b, field, ascending) => {
    if (ascending) {
      if (a[field] < b[field]) {
        return -1
      } else {
        return 1
      }
    } else {
      if (a[field] < b[field]) {
        return 1
      } else {
        return -1
      }
    }
  }
  
  const fetchData = () => {
  	get("/user/api/v2/user/auth", token)
  	.then(res => {
  		if (!res.isError) {
  			setIsLoaded(true)
  			setItems(res.data)
  		} else {
  			setError(res.error)
  		}
  	})
  }
  
	
	useEffect(() => {
		fetchData()
	}, [])

  const handleSortSelection = (field) => {
      
    if (field!==orderBy) {
        setOrderBy(field)
        
    } else {
        setAsc(!asc)
        
    }
      
  }
  
  if (user.is_admin) {
    return (
      
			<Box sx={{ mt: 3 }}>
		  <Table>
		    <TableHead>
		    <TableRow>
		      <TableCell >Email</TableCell>
		      <TableCell >Authentication Level</TableCell>
		      <TableCell >Is Admin</TableCell>
		      <TableCell >Is Active</TableCell>
		      <TableCell >Registration Time</TableCell>
		      <TableCell></TableCell>
		    </TableRow>
		    </TableHead>
		    <TableBody>
		    {error
		    	? <>{error}</>
		    	: !isLoaded
		    		? <LinearProgress />
		    		: items
					.sort((a,b) => compare(a, b, orderBy, asc))
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		      .map(item => (
						<TableRow key={item.email}>
							<TableCell>{item.email}</TableCell>
							<TableCell>
								{item.auth_lvl} 
							</TableCell>
							<TableCell align='center'>
								<Checkbox checked={item.is_admin} />
							</TableCell>
							<TableCell align='center'>
								<Checkbox checked={item.active} />
							</TableCell>
							<TableCell align='center'>
								 {item.registration_time} 
							</TableCell>
							<TableCell>
								<EditUser item={item} 
									onChange={fetchData}
									/>
								<DeleteUser item={item} 
									onChange={fetchData}
							 	 />
							</TableCell>
					 </TableRow>
		     ))}
		    </TableBody>
		  </Table>
		  
			<TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component="div"
				count={items.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(e, p) => setPage(p)}
				onRowsPerPageChange={handleChangeRowsPerPage}
				/> 
		  </Box>
    );
  } else {
    return <p align='center'>You do not have permission to view this page.</p>
  }
  return (
  	<></>
  )
    
}
