import {
	Typography,
	Grid,
	Box,
	Stack,
	LinearProgress
} from "@mui/material";

import {
	ScoreTable,
	ScoreLineChart,
	NewsTable,
	WatchlistTool,
	Search
} from "../components"
import { useState, useEffect } from "react"
import { get, put, post } from "../components/Functions"
import Cookies from "js-cookie";



function MarketSnapshot(props) {
  const markets = JSON.parse(localStorage.getItem('markets'))
  
  const fetchMarket = (i,s,f,r,p) => {
		
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		let res = get("/score/api/v2/score/" 
			+ p
			+ "?interval=" + i
			+ "&start=" + s.toISOString()
			+ "&finish=" + f.toISOString(),
			token
		).then(res => {
			if (!res.isError) {
				res.data = res.data.flat()
				return {...res}
			} else {
				return {...res}
			}
		})
		
		return res
	}
  
  return (
		<ScoreTable 
				onFetch={fetchMarket}
				portfolios={{
					values: markets,
					visible: true
				}}
				defaultPortfolio={markets[0]}
				/>
	)
}

function WatchlistSnapshot(props) {
	const user = JSON.parse(localStorage.getItem('user'))
	
  const [watchlists, setWatchlists] = useState([])
  const [watchlistNames, setWatchlistNames] = useState([])
  const [watchlist, setWatchlist] = useState(null)
  
  
  const handleAddtoWatchlist = (h) => {
		let list_ = watchlists.filter((i) => i._id["$oid"]===watchlist)[0].items
		let wl = list_.map((v,k) => {
			if (v.market===h.market && v.issuer_ticker===h.issuer_ticker) {
				return k
			} else {
				return -1
			}
		}).filter(i => i>-1)
		
		if (wl.length>0) {
			
			list_.splice(wl[0], 1)
			
		} else {
		
			list_.push({ market: h.market , issuer_ticker: h.issuer_ticker })
		}
  	if (user) {
  		
			let token = Cookies.get("AIDATAME_AUTH_TOKEN")
	  	put("/user/api/v2/watchlist/" 
	  		+ watchlist, 
	  		{ items : list_},
	  		token
  		).then(res => {
				if (!res.isError) {				
					handleWatchlistChange()
				} else {
					console.log(res.error)
				}
			})
  	} else {
  	}
  }
  
  const handleWatchlistChange = () => {
  	if (user) {
  		
			let token = Cookies.get("AIDATAME_AUTH_TOKEN")
	  	get("/user/api/v2/user/watchlists/" + user.email, token)
			.then(res => {
				if (!res.isError) {				
					setWatchlists(res.data)			
					setWatchlist(watchlist && res.data.filter(i => i._id["$oid"]===watchlist).length>0 ? watchlist : res.data[0]._id["$oid"])
					setWatchlistNames(res.data.map((i) => {return i.name}))
				} else {
					console.log(res.error)
				}
			})
  	} else {
  	}
  }
  
	const fetchWatchlist = (i,s,f,r,p) => {
		
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		let res = post("/score/api/v3/score" 
			+ "?interval=" + i
			+ "&start=" + s.toISOString()
			+ "&finish=" + f.toISOString(),
			{ 
				data: p
			},
			token
		).then(res => {
			if (!res.isError) {
				return {...res}
			} else {
				return {...res}
			}
		})
		
		return res
	}
	
	const handleMapFunction = (h) => {
		
		let list_ = watchlists.filter((i) => i._id["$oid"]===watchlist)[0].items
		let wl = list_.filter((i) => i.market===h.market && i.issuer_ticker===h.issuer_ticker)
		
		if (wl.length>0) {
			h.customdata = { in_watchlist: true }
		} else {
			h.customdata = { in_watchlist: false }
		}
		return h
	}
	
	  
  useEffect(() => {
  	handleWatchlistChange()
  }, [])
  
	
	return (
		<ScoreTable 
			portfolios={{
				values: watchlistNames,
				visible: true
			}}
			pendingChange={(v,i) => {
				var id_ = watchlists[i]._id["$oid"]
				setWatchlist(id_)
				return watchlists[i].items
			}}
			onFetch={fetchWatchlist}
			subActions={
				<Stack direction="row"
					spacing={1}
					>
					<Search 
  					sx={{ borderRadius: 0, width: 1 }}
  					onSecondaryAction={handleAddtoWatchlist}
  					onHitClick={(h) => {handleAddtoWatchlist(h)}}
  					mapFunction={handleMapFunction}
						/>   
					<WatchlistTool 
						item={watchlist}
						onChange={() => handleWatchlistChange()}
						/>
				</Stack>
			}
			onEmptyListShow={
				<Typography align="center">Your watchlist is empty.</Typography>
			}
			defaultPortfolio={watchlist ? watchlists.filter(i => i._id["$oid"]===watchlist)[0].items : []}
			/>
	)
	
}


function HomePage (props) {    
  
  const markets = JSON.parse(localStorage.getItem('markets'))
  
  return (
    <Box>
    <Typography variant={"h3"}
    	align="center"
    	sx={{ mt: 3, mb: 3}}
    	>Data at the speed of life.</Typography>
    <Grid container 
    	spacing={2}
			>
			<Grid item
				xs={12}
				sm={12}
				lg={3}
				>
				
			<WatchlistSnapshot />
			<MarketSnapshot />
				
      </Grid>
      <Grid item
				xs={12}
				sm={12}
				lg={6}
				>
				<ScoreLineChart 
					portfolios={{
						values: markets,
						visible: true
					}}
					defaultList={[
						{market: markets[0]},
					]}
					{...props}
					/>
			</Grid>
      <Grid item
				xs={12}
				sm={12}
				lg={3}
				>
			</Grid>
  	</Grid>
    </Box>
  );

}


export default HomePage
