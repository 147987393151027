import { useState, useEffect } from 'react'
import { Box,
	Stack, 
	Button,
	Typography,
	Table,
	TableRow,
	TableCell,
	TextField,
	Collapse,
	Divider
} from "@mui/material"
import Cookies from "js-cookie";
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { put } from "../components/Functions"
import Catalog from "../components/Catalog"

const stripe = require("stripe")(process.env.REACT_APP_STRIPE_API_LIVE)

function AccountSettingPage (props) {
  
  const user = JSON.parse(localStorage.getItem('user'))
  const [isOpen, setIsOpen] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [products, setProducts] = useState([])
  
  const [currentPlan, setCurrentPlan] = useState(null)
  
  const handleSaveProfile = () => {
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
  	put("/user/api/v2/user/auth",
  		{ family_name: lastName, given_name: firstName},
  		token
  	).then((res) => {
  		if (!res.isError) {
  			localStorage.setItem("user", JSON.stringify(res.data))
  			setFirstName(res.data.given_name)
  			setLastName(res.data.family_name)
  			setEditProfile(false)
  		} else {
  			alert(res.error)
  		}
  	})
  }
  
  const fetchProducts = () => {
  	stripe.prices.search({
  		query: 'active:\'true\' AND currency:\'eur\''
		}).then(res => {
			
			setProducts(res.data)
		})
  	
  }
  
  const fetchCurrentPlan = () => {
  	if (user.subscription_id) {
  		stripe.subscriptions.retrieve(user.subscription_id)
  		.then(res => {
  			if (res.status==="active") {
  				let p = res.items.data.sort((a,b) => {
  					if (a.price.unit_amount < b.price.unit_amount) {
  						return 1
  					} else {
  						return -1
  					}
  				})
  				setCurrentPlan(p[0].price.id)
  			}
  		})
  	}
  }
  
  useEffect(() => {
  	fetchProducts()
		fetchCurrentPlan()
  }, [])
  
  if (user) {    
    return (
    	
      <Box
      	sx={{ mt: 5 , mb: 5 }}
      	>
      <Stack direction="column"
      	spacing={5}
      	>
     	<Box>
     		<Typography variant="h5"
     			sx={{ mb : 1}}
     			>
     			<PersonIcon sx={{ fontSize: 35 }}/> &nbsp;
     			Profile &nbsp;&nbsp;
     			<Button variant="outlined"
     				onClick={() => setEditProfile(!editProfile)}
     				>
     				Edit profile
   				</Button>
     		</Typography>
     		
     		<Divider />
     		<Table
     			sx={{ maxWidth: 500, mb: editProfile ? 3 : 0  }}
     			>
     		<TableRow>
     			<TableCell>
     				<Typography variant="subtitle1">Email</Typography>
   				</TableCell>
   				<TableCell>{user.email}</TableCell>
     		</TableRow>
     		<TableRow>
     			<TableCell>
     				<Typography variant="subtitle1">First Name</Typography>
   				</TableCell>
   				<TableCell>
   					{editProfile 
   						? <TextField variant="standard"
   								defaultValue={user.given_name}
   								onChange={(e) => setFirstName(e.target.value)}
   								value={firstName}
   								/>
   						: user.given_name
 						}
 					</TableCell>
     		</TableRow>
     		<TableRow>
     			<TableCell>
     				<Typography variant="subtitle1">Last Name</Typography>
   				</TableCell>
   				<TableCell>
   					{editProfile 
   						? <TextField variant="standard"
   								defaultValue={user.family_name}
   								onChange={(e) => setLastName(e.target.value)}
   								value={lastName}
   								/>
   						: user.family_name
 						}
   				</TableCell>
     		</TableRow>
   			</Table>
   			
   			{editProfile
   				? <Button variant="contained"
			 				onClick={() => handleSaveProfile()}
			 				>
			 				Save changes
			 			</Button>
		 			: ""
	 			}
     	</Box>
     	
     	<Box>
     		<Typography variant="h5"
     			sx={{ mb : 1}}
     			>
     			<CreditCardIcon sx={{ fontSize: 35 }}/> &nbsp;
     			Subscription Plans &nbsp;&nbsp;
     			<Button variant="outlined"
     				onClick={() => setIsOpen(!isOpen)}
     				>
     				Manage plan
   				</Button>
     		</Typography>
     		<Divider />
     		
     		<Collapse
     			in={isOpen}
     			>
     			<Stack 
     				direction={{ lg: "row", md: "column", sm: "column", xs: "column" }}
     				spacing={1}
     				sx={{ mt: 1}}
     				>
     		{products.map((p) => (
     			<Catalog key={p.id}
     				item={p}
     				activePlan={currentPlan===p.id}
     				/>
     		))}
     			</Stack>
     		</Collapse>
     		
     	</Box>
			</Stack>
      </Box>
    );
  } else {
    return (
      <Typography align="center">
      	Login or Sign-up to our website.
      </Typography>
    )
  }

}

export default AccountSettingPage;


