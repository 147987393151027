import {
	CustomNavbar,
	Footer
} from "../components";
import { Divider, Box } from "@mui/material"

function Main({content, ...props}) {
	return (
		<>
		<CustomNavbar {...props}/>
		<Box sx={{ ml: 3, mr: 3 }}>
		{content}
		</Box>
		<Divider />
		<Footer />
		</>
	)
}

export default Main;
