import { useState, useEffect } from 'react'
import { 
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination,
    Chip,
    Typography,
    Paper,
    Box
} from "@mui/material"

export default function News({items, ...props}) {
    
	
	const [asc, setAsc] = useState(true);
	const [orderBy, setOrderBy] = useState(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	
	const handleChangeRowsPerPage = (e) => {
		setRowsPerPage(parseInt(e.target.value, 10));
    	setPage(0);
	}
	
	useEffect(() => {}, [items])
  
  const now = new Date()
  const delta = new Date(now).getTimezoneOffset() * 60000


	return (
	    <div>
      	<Table >
          <TableHead>
          <TableRow>
              <TableCell>TIME</TableCell>
              <TableCell>SOURCE</TableCell>
              <TableCell>TONE</TableCell>
              <TableCell>LANG</TableCell>
              <TableCell>ESG</TableCell>
          </TableRow>
          </TableHead>
          
          <TableBody>    
          {items.length>0
            ? items
		  			.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		        .map((r) => (
		            <TableRow key={r.DocumentIdentifier} 
		            	onClick={(e) => window.open(r.DocumentIdentifier, "_blank")}
		            	hover
		            	>
		            	
		            	<TableCell>
		            	{(now - Date.parse(r.DATE) + delta) / 86400000 > 1
		            		? <>{parseInt((now - Date.parse(r.DATE) + delta) / 86400000, 0)} days ago</>
		            		: (now - Date.parse(r.DATE) + delta) / 3600000 > 1
		            			? <>{parseInt((now - Date.parse(r.DATE) + delta) / 3600000, 0)} hours ago</>
		            			: <>{parseInt((now - Date.parse(r.DATE) + delta) / 60000, 0)} minutes ago</>
		            	}
		            	</TableCell>
		            	
		            	<TableCell>
		            		<Typography variant="subtitle2" gutterBottom>
				          	{r.SourceCommonName.length < 20
				          		? r.SourceCommonName
				          		: r.SourceCommonName.slice(0,20) + "..."
				          	}
				          	</Typography>
				          	<Typography
				          		variant="body2"
				          		>
				          		Themes: &nbsp;
				          		{r.Themes.slice(0,5).join(" • ")}
				            </Typography>
		              
		            	</TableCell>
		            	
		            	<TableCell align="right"
		            		>
						    		<Typography variant="inherit"
						        		sx={{ color : r.Tone>0 ? "green" : "red"}}
						        		>{r.Tone>0 ? "+" : ""}{r.Tone.toFixed(2)}
						    		</Typography>
		        		
		            	</TableCell>
		            	
		            	<TableCell>{r.TranslationInfo.toUpperCase()}</TableCell>
		            	<TableCell>
		            	{r.ESG.map((i) => (
		            		<Chip key={i}
		            			label={i} 
		            			size="small"
		            			color={i==="E"
		            				? "success"
		            				: i==="S"
		            					? "warning"
		            					: "error"
		            			}
		            			/>
              		))}
		            	</TableCell>
		          </TableRow>
		          ))
		          
          		: <></>
            }
        </TableBody>      
          
      </Table>
      
      <TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component="div"
				count={items.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(e, p) => setPage(p)}
				onRowsPerPageChange={handleChangeRowsPerPage}
				/>
    </div>
	)

}


