import { useState, useEffect } from 'react'
import { 
    LinearProgress,
    Paper,
    Box
} from "@mui/material"
import { get } from "./Functions"
import News from "./Table/News"
import Cookies from "js-cookie";

export default function NewsTable({market, item, ...props}) {
    
	const oneHourToMilliSeconds = 360000;
	
	const [items, setItems] = useState([]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	
	const handleOnRefresh = (market, item = null) => {
		setIsLoaded(false)
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		get("/news/api/v1/news/" 
			+ market 
			+ (item && item.issuer_ticker ? "?ticker=" + item.issuer_ticker : ""),
			token
		).then(res => {
			if (!res.isError) {
				setItems(res.data)
				setIsLoaded(true)
			} else {
				setError(res.error)
			}
		})
	}
	
	useEffect(() => {
		handleOnRefresh(market, item)
	}, [market, item])
  
	return (
	    <Box >
	    <Paper elevation={0}
	    	variant="card-header"
	    	sx={{ lineHeight: 2.4, pl: 1 }}
	    	>
	    	RECENT NEWS
    	</Paper>
    	{error
    		? <>{error.message}</>
  			: !isLoaded
  				? <LinearProgress />
  				: <News items={items} />
    	}
      </Box>
	)

}


