import { Container,
	Input,
	Button,
	Divider,
	InputAdornment,
	IconButton,
	FormControl,
	FormControlLabel,
	InputLabel,
	Checkbox,
	Alert
} from "@mui/material";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import logo from '../logo.png'
import { useState } from "react";

import { post } from "../components/Functions";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function Login({isMember, ...props}) {
	let navigate = useNavigate();
	
	const minLength = 8;
	
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [error, setError] = useState(null)
  //const [isLoaded, setIsLoaded] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  const [rmbMe, setRmbMe] = useState(false)
    	
	const handleLogin = (e, p) => {
		let expire = rmbMe ? 7 : 1
		
		Promise.all([
			post("/user/api/v2/user/auth", {email: e, password: p})
			.then(res => {
				if (!res.isError) {
					let user = res.data;
					delete user["password"]
					localStorage.setItem("user", JSON.stringify(user))
					setError(null)
					return user
				} else {
					setError(res.error)
				}
			}),
			post("/user/api/v2/user/token", {email: e, password: p, expire: expire * 24 * 3600})
			.then(res => {
				if (!res.isError) {
					
					Cookies.set("AIDATAME_AUTH_TOKEN", res.data.token, {expires: expire})
				} else {
					console.log(res.error)
				}
			})
		]).then(res => {
				
				navigate("/")
			})
		
		
	}
	
	const handleSignUp = (e, p) => {
		if (!p || p.length<minLength) {
			setError("Your password must contain at least 8 characters.")
		} else {
			post("/user/api/v2/user/sign-up/token", {email: e, password: p})
			.then(res => {
				if (!res.isError) {
					navigate("/login")
					alert(res.json())					
					setError(null)
				} else {
					setError(res.error)
				}
			})
		}
	}
	
	const handleGoogleLoginSuccess = (credential) => {
		const userObject = jwt_decode(credential)
		
		let expire = rmbMe ? 7 : 1
		
		const user = {
			email: userObject.email,
			family_name: userObject.family_name,
			given_name: userObject.given_name,
			name: userObject.name,
			email_verified: userObject.email_verified
		}
		localStorage.setItem("user", JSON.stringify(user))
		
		Promise.all([
			post("/user/api/v2/user/auth", user)
			.then(res => {
				if (!res.isError) {
					
					let user = res.data;
					delete user["password"]
					localStorage.setItem("user", JSON.stringify({...user, picture : userObject.picture}))
				} else {
					console.log(res.error)
					
				}
			}),
			post("/user/api/v2/user/token", {email: userObject.email, email_verified: userObject.email_verified, expire: expire * 24 * 3600})
			.then(res => {
				if (!res.isError) {
					
					Cookies.set("AIDATAME_AUTH_TOKEN", res.data.token, {expires: expire})
				} else {
					console.log(res.error)
				}
			})
			
		]).then(res => {
			navigate("/")
			})
		
		
	}
	

	return (
	
		<Container 
			sx={{
				position: "fixed", 
				top: "30%", 
				transform: "translate(-50%,-30%)",
				left: "50%",
				width: "100%",
				maxWidth: "400px",
				border: "0.1rem solid",
				pb: 5,
				pt: 5
			}}
			>
			<div align="center"><img src={logo} atl='logo' width={80} alt="logo"/></div>
			
			<FormControl variant="standard" 
				fullWidth
				>
            
            <InputLabel htmlFor="standard-adornment-password">Email</InputLabel>
			
			<Input
				placeholder="Email"
				type="text"
				onChange={(e) => setEmail(e.target.value.toLowerCase())}
				/>
				
			</FormControl>
			<FormControl fullWidth variant="standard">
            
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
			
			<Input
				fullWidth={true}
				placeholder={"Password"}
				type={showPwd ? "text" : "password"}
				onChange={(e) => setPwd(e.target.value)}
				endAdornment={
					<InputAdornment position="end" edge="end">
					
					<IconButton onClick={() => setShowPwd(!showPwd)}>
					
						{showPwd
							? <VisibilityIcon />
							: <VisibilityOffIcon />
						}
					</IconButton>
					</InputAdornment>
					}
				/>
			</FormControl>
			<br/>
			{error
				? <Alert severity="error">{error}</Alert>
				: <></>
			}
			
        	<Button variant="text" 
        			size="small"
        			sx={{ textTransform: "none", fontWeight: 400 }}
        			onClick={() => navigate("/password/reset")}
        			>
        		Forgot your password ?
    		</Button>
    		
    		<div>
    		<FormControlLabel control={<Checkbox size="small" />} 
					label={"Remember me"} 
					size="small"
					checked={rmbMe}
					onChange={() => setRmbMe(!rmbMe)}
					/>
			</div>	
					
			<Button variant="contained" 
				fullWidth={true}
				sx={{marginTop: "20px"}}
				onClick={() => isMember ? handleLogin(email, pwd) : handleSignUp(email, pwd)}
				>
				
				{isMember
					? "Login"
					: "Sign-up"
				}
			</Button>
			{isMember
            ? <div sx={{marginTop: "5pt"}}>Don't have an account ?
            	<Button variant="text" 
            			size="small"
            			sx={{ textTransform: "none", fontWeight: 400 }}
            			onClick={(e) => navigate("/sign-up")}
            			>
            		Sign-up
        		</Button>
            </div>
            : <div sx={{marginTop: "5pt"}}>Already have an account ? 
            	<Button variant="text" 
            			size="small"
            			onClick={(e) => navigate("/login")} >
            		Login
        		</Button>
        		</div>
			}
			
			<Divider sx={{marginTop: "15pt", marginBottom: "15pt"}}>OR</Divider>
			<GoogleLogin
				onSuccess={res => handleGoogleLoginSuccess(res.credential)}
				onError={res => {
					console.log(res);
				}}
				text={"continue_with"}
				width="366px"
				/>
			
		</Container>
		
	)
}

export default Login;
