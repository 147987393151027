import {
    Tabs,
    Tab,
    LinearProgress,
    Typography,
    Chip,
    Box
} from '@mui/material'
import { useParams } from 'react-router-dom';
import { useEffect , useState } from 'react'
import { get } from "../components/Functions"
import Overview from "./Overview"
import Dashboard from "./Dashboard"


function TickerDetail(props) {
    const user = JSON.parse(localStorage.getItem('user'))
    
		let { market, ticker } = useParams();
	
    const [item, setItem] = useState(null)
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    
    useEffect(() => {
        document.title = "esg.cafe - " + ticker
        
        get("/score/api/v1/info/" + market + "?ticker=" + ticker)
        .then(res => {
            if (!res.isError) {
                setItem(res.data)
		        		setIsLoaded(true)
                
            } else {
                setError(res.error)
                
            }
        })
        
        
    }, [market, ticker])


    return (
    <div>
    <br/>
    {error
    	? <></>
    	: !isLoaded
    		? <LinearProgress />
    		: <div>
    			<Typography variant="h3">
    				{item.Name.toUpperCase()}
    				&nbsp;&nbsp;<Chip label={ticker} />
				</Typography>
				
				<Typography variant="subtitle2">
    				{item.Sector}
    				&nbsp;&bull;&nbsp;{item.Location}
				</Typography>
    		</div>
    } 
	<Tabs
        value={activeTab}
        
        >
		<Tab label="Overview" value={0}
			onClick={() => setActiveTab(0)}
			/>
		<Tab label="Charts" value={1} 
			onClick={() => setActiveTab(1)}
			/>
	</Tabs>
	
	<Box sx={{ mt: 3}}>
	
	{activeTab===0
		? item
			? <Overview item={item} market={market}/>
			: <></>
		: activeTab===1
			? <Dashboard item={item} market={market}/>
			: <></>
	}
	</Box>
        
    </div>

   );
    
    
    
}

export default TickerDetail;
