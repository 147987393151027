import { useState, useEffect } from 'react'
import Cookies from "js-cookie";
import { Modal, 
	Paper,
	Stack,
	Button,
	IconButton,
	Menu,
	MenuItem ,
	Typography,
	TextField
} from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { put, post, del } from "./Functions"

function NewWatchlist({open, onClose, onChange, ...props}) {
	const [isOpen, setIsOpen] = useState(open)
	const [name, setName] = useState("My watchlist")
	
	const handleOnClose = () => {
		setIsOpen(false)
		onClose(false)
	}
	
	const handleOK = () => {
		setIsOpen(false)
		onClose(false)
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		post("/user/api/v2/watchlist/create",
			{
				name: name
			},
			token
		).then(res => {
			if (!res.isError) {
				handleOnClose()
				onChange()
			} else {
				alert(res.error)
				handleOnClose()
			}
		})
	}
	
	useEffect(() => {
		setIsOpen(open)
	}, [open])
	
	return (
		<Modal
			open={open}
			onClose={onClose}
			>
			<Paper variant="modal"
				sx={{ pl: 5, pr: 5, pt: 2, pb: 2 }}
				>
			<Typography variant="h6" component="h2">
		    New watchlist
		  </Typography>
		  <TextField label="Name"
		  	variant="standard"
		  	defaultValue={name}
		  	value={name}
		  	onChange={(e) => setName(e.target.value)}
		  	/>
	  	<Stack spacing={0.5}
	  		justifyContent="flex-end"
	  		direction="row"
	  		mt={2}
	  		>
	  		<Button
					variant="contained"
					onClick={() => handleOK()}
					>OK</Button>
				<Button
					variant="outlined"
					onClick={() => handleOnClose(false)}
					>Cancel</Button>
	  	</Stack>
			</Paper>
		</Modal>
	)
}


function DeleteWatchlist({open, onClose, onChange, item, ...props}) {
	const [isOpen, setIsOpen] = useState(open)
	
	const handleOnClose = () => {
		setIsOpen(false)
		onClose(false)
	}
	
	const handleOK = () => {
		setIsOpen(false)
		onClose(false)
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		del("/user/api/v2/watchlist/" + item, null, token)
		.then(res => {
			if (!res.isError) {
				handleOnClose()
				onChange()
			} else {
				alert(res.error)
			}
		})
	}
	
	useEffect(() => {
		setIsOpen(open)
	}, [open, item])
	
	return (
		<Modal
			open={open}
			onClose={onClose}
			>
			<Paper variant="modal"
				sx={{ pl: 5, pr: 5, pt: 2, pb: 2 }}
				>
			<Typography variant="h6" component="h2">
		    Are you sure to delete this watchlist ?
		  </Typography>
	  	<Stack spacing={0.5}
	  		justifyContent="flex-end"
	  		direction="row"
	  		mt={2}
	  		>
	  		<Button
					variant="contained"
					onClick={() => handleOK()}
					>OK</Button>
				<Button
					variant="outlined"
					onClick={() => handleOnClose()}
					>Cancel</Button>
	  	</Stack>
			</Paper>
		</Modal>
	)
}


function RenameWatchlist({open, onClose, onChange, item, ...props}) {
	const [isOpen, setIsOpen] = useState(open)
	const [name, setName] = useState(null)
	
	const handleOnClose = () => {
		setIsOpen(false)
		onClose(false)
	}
	
	const handleOK = () => {
		setIsOpen(false)
		onClose(false)
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		put("/user/api/v2/watchlist/" + item,
			{ name: name },
			token
		).then((res) => {
			if (!res.isError) {
				onChange()
				handleOnClose()
			} else {
				alert(res.error)
				handleOnClose()
			}
		})
	}
	
	useEffect(() => {
		setIsOpen(open)
	}, [open])
	
	return (
		<Modal
			open={open}
			onClose={onClose}
			>
			<Paper variant="modal"
				sx={{ pl: 5, pr: 5, pt: 2, pb: 2 }}
				>
			<Typography variant="h6" component="h2">
		    Rename watchlist
		  </Typography>
		  <TextField label="Name"
		  	variant="standard"
		  	value={item ? item.name : ""}
		  	onChange={(e) => setName(e.target.value)}
		  	/>
	  	<Stack spacing={0.5}
	  		justifyContent="flex-end"
	  		direction="row"
	  		mt={2}
	  		>
	  		<Button
					variant="contained"
					onClick={() => handleOK()}
					>OK</Button>
				<Button
					variant="outlined"
					onClick={() => handleOnClose()}
					>Cancel</Button>
	  	</Stack>
			</Paper>
		</Modal>
	)
}


function WatchlistTool({onChange, ...props}) {
	const [anchorEl, setAnchorEl] = useState(null)
	
	const [open1, setOpen1] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [open3, setOpen3] = useState(false)

	
	useEffect(() => {}, [props.item])
	
	return (
		<>
		<IconButton
			onClick={(e) => setAnchorEl(e.currentTarget)}
			>
			<MoreHorizIcon />
		</IconButton>
		<Menu
			open={Boolean(anchorEl)}
			onClose={() => setAnchorEl(null)}
			anchorEl={anchorEl}
			dense
			>
			<MenuItem
				onClick={() => {setOpen1(true); setAnchorEl(null)}}
				>
				<AddIcon />&nbsp;
				New watchlist
			</MenuItem>
			<MenuItem
				onClick={() => {setOpen2(true); setAnchorEl(null)}}
				
				>
				<DeleteIcon />&nbsp;
				Delete
			</MenuItem>
			<MenuItem
				onClick={() => {setOpen3(true); setAnchorEl(null)}}
				>
				<ModeEditIcon />&nbsp;
				Rename
			</MenuItem>
		</Menu>
		
		<NewWatchlist 
			open={open1}
			onClose={(v) => setOpen1(v)}
			onChange={onChange}
			{...props}
			/>
			
		<DeleteWatchlist 
			open={open2}
			onClose={(v) => setOpen2(v)}
			onChange={onChange}
			{...props}
			/>
			
		<RenameWatchlist 
			open={open3}
			onClose={(v) => setOpen3(v)}
			onChange={onChange}
			{...props}
			/>
		</>
	)
    
}

export default WatchlistTool;
