import {
	Typography,
	Box,
	Grid,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Paper
} from "@mui/material";
import { ScoreLineChart,
	NewsTable 
} from "../components";

export default function Overview({item, market, ...props}) {

  return (
		<Box>
		<Grid container 
			spacing={2}
			>
			<Grid item
				sm={12}
				lg={3}
				>
			<div>
			<Paper 
				variant="card-header"
				sx={{ lineHeight: 2.4, pl: 1 }}
				>
				KEY INFO
			</Paper>
			
			<Table >
			
			<TableBody>
			{["Asset Class","Sector","Exchange","Location","ISIN"].map(k => (
				<TableRow key={k}>
				<TableCell>{k}</TableCell>
				<TableCell>{item[k]}</TableCell>
				</TableRow>
			))}
			</TableBody>
			
			</Table>
			</div>
			</Grid>
			<Grid item
				sm={12}
				lg={6}
				>
				<ScoreLineChart
					defaultList={[
					{market: market},
					{market: market, issuer_ticker: item.Sector},
					{market: market, issuer_ticker: item["Issuer Ticker"]}
					]}
					portfolios={{values: [market], visible: false}}
				/>
			</Grid>
			<Grid item
				sm={12}
				lg={3}
				>
			</Grid>
		</Grid>
		</Box>
    );
}
