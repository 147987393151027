import { 
    Paper,
    Stack,
    Divider,
    Button,
    Menu,
    MenuItem
} from '@mui/material'
import {
	Markets,
	TimeSettingTool
} from "../components"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState, useEffect } from "react"

function CardActions({
		options,
		onScoreChange,
		onTimeChange,
		onPortfolioChange,
		subActions,
		...props	
	}) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [item, setItem] = useState(options.score.values[0])
	
	const handleScoreOptionClick = (s) => {
		setItem(s)
		onScoreChange(s)
		setAnchorEl(null)
	}
	
	useEffect(() => {}, [options])

	return (
		<>
		<Paper
			variant="card-header"
			>
		<Stack direction="row"
			divider={<Divider 
						orientation="vertical" 
						flexItem 
						variant="middle" />
			}
			spacing={0}
			>
			<Button
				variant="text"
				disableElevation
				onClick={(e) => setAnchorEl(e.currentTarget)}
				endIcon={<ArrowDropDownIcon />}
				sx={{
					fontWeight : 600,
					color: "inherit",
					minWidth: 100,
					padding: "0 0 0 0",
				}}
				>
				{item}
			</Button>
			<TimeSettingTool onItemClick={onTimeChange} />
				
			{options.portfolios.visible
				? <Markets items={options.portfolios.values} 
					onItemClick={onPortfolioChange}
					/>
				: <></>
			}
		</Stack>
						
		<Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={() => setAnchorEl(null)}
			>
			{options.score.values.map(s => (
				<MenuItem key={s} value={s}
					onClick={() => handleScoreOptionClick(s)}
					>{s}</MenuItem>
			))}
		</Menu>
		</Paper>
		
		{subActions}
		</>
	)
}


export default CardActions;
