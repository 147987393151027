import { Bar } from 'react-chartjs-2';
import { colors } from './Converter'
import { useState, useEffect } from 'react'

export default function RelatedCompanies({market, items, ...props}) {
    
  const [data, setData] = useState(null)
  
  const prepareData = (items) => {
  	
  	var arr = Object.keys(items).map(function(key) {
			return [key, items[key]];
		});
		
  	var x = arr.sort((a,b) => {
  		return b[1] - a[1]
  	}).slice(1,11)
  	
		
	  setData({
			labels: x.map(i => i[0]),
			datasets: [{
				label: "# appearance in same news",
		    data: x.map(i => i[1]),
			}]
		})
  }
  
  useEffect(() => {
  	if (items) {
  		
	  	prepareData(items)
  	}
  }, [items])
  
  const handleClick = (element) => {
      
    if (element[0]) {
      const ticker = items[element[0].index]
      window.open('/' + market + '/' + ticker)
    }
  }
  
  const options = {
    scales: {
      x: {
        grid: {display: false},
      },
      y: {
        grid: {display: false},
        title: {
          text: '# in same news',
          display: true,
        }
      },
        
    },
    plugins: {
      legend: {display: false},
      tooltip: {
        enabled: true,
        intersect: false
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    onClick: function (event, element) {
      handleClick(element)
    }
  }
  if (data) {
  	
		return (
		    <Bar options={options} data={data}/>
		)
  } else {
  	return <></>
  }
  
}
