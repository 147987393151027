import { Line } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { 
    LinearProgress,
    Box,
    Stack
} from "@mui/material";
import { convert } from "./Chart/Converter";
import { get } from "./Functions"
import {
	CardActions,
	NewsTable,
	Search
} from "../components";
import LineChart from "./Chart/Line"
import Cookies from "js-cookie";


export default function ScoreLineChart({portfolios, defaultList, ...props}) {
		
	const oneHourToMilliSeconds = 360000;
    
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
  const [rawData, setRawData] = useState({});
	
	const [scoreOptions, setScoreOptions] = useState(["ESG Score","SDG Score"]);				
	const [score, setScore] = useState(scoreOptions[0]);
	const [portfolio, setPortfolio] = useState(portfolios.values[0]);
	
  const [items, setItems] = useState([]);
	const [range, setRange] = useState(null);
	const [interval, setInterval] = useState(null);
	const [start, setStart] = useState(null);
	const [finish, setFinish] = useState(null);  
  
  const handleGetData = (interval, start, finish, range, h=null) => {
  	setIsLoaded(false)
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
  	if (h) {
			get("/score/api/v2/time-series/"
					+ h.market 
					+ "?interval=" + interval 
					+ "&start=" + start.toISOString()
					+ "&finish=" + finish.toISOString()
					+ (h.issuer_ticker ? "&ticker=" + h.issuer_ticker : ""),
					token
					
			).then(res => {
				if (!res.isError) {
					
					setScoreOptions(Object.keys(res.data))
					setRawData((prevState) => {
						prevState[h.issuer_ticker ? h.issuer_ticker : h.market] = res.data
						return { ...prevState }
					})
					setItems((prevState) => {
						prevState.push(h)
						return prevState
					})
					
				} else {
					setError({ message : "Cannot fetch one or more ticker(s)"})
				}
				
				setIsLoaded(true)
				setRange(range)
				setStart(start)
				setFinish(finish)
				setInterval(interval)
			  })
	    
  	} else {
  		items.forEach((i) => {
  			handleGetData(interval, start, finish, range, i)
  		})
  		
  	}		
  	
      
  }
  
  const handlePortfolioChange = (v,i) => {
  	setPortfolio(v)
  	setRawData({})
  	setItems([])
  	handleGetData(interval, start, finish, range, {market : v} )
  }
  
  
  useEffect(() => {
    const now = new Date()
    const finish = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
    var start = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
    start.setMonth(start.getMonth() - 3)
    
    if (defaultList) {
    	setItems([])
    	setRawData({})
    	setPortfolio(portfolios.values[0])
    	defaultList.forEach((l) => {
    		handleGetData("1D", start, finish, "3M", l)
    	})
    } else {
    	handleGetData("1D", start, finish, "3M", {market: portfolio})
    }
      
      
  }, [defaultList])    
  
  return (
      
    <Stack spacing={2}
    	>
    	<Box>
      <CardActions 
		  	options={{
		  		score: { values: scoreOptions, visible: true},
		  		portfolios: portfolios,
		  	}}
		  	onScoreChange={setScore}
		  	onTimeChange={handleGetData}
		  	onPortfolioChange={handlePortfolioChange}
		  	subActions={
				  <Search onHitClick={(h) => handleGetData(interval, start, finish, range, h ) } 
				  	filters={"market:" + portfolio}
				  	sx={{ borderRadius: 0 }}
				  	/>   	
				}
		  	{...props}
		  	/>
    	
      {!isLoaded
      	? <LinearProgress />
      	: <></>
      }	
      {error
          ? <p>{error.message}</p>
          : <LineChart items={rawData}
          		index={score}
          		interval={interval}
          		/>
      }
      	
			</Box>
			
      <NewsTable market={portfolio}
      	item={items.length > 1 ? items[items.length - 1] : null}
      	/>
      
    </Stack>
  );

}
