import { 
    Stack,
    Paper,
    Link,
    Typography
} from "@mui/material";


function Footer(props) {
        
    return (
        <Stack 
        	direction="row" 
        	spacing={15} 
        	sx={{ ml: 5, mt: 3 }}
        	>
        
        <Paper elevation={0}>
            <Typography variant={"h6"}>About</Typography>
            <p><Link href='https://app.termly.io/document/terms-of-use-for-website/759057cc-d400-4167-97ca-a8a31bb95e36'>Terms of Service</Link></p>
            <p><Link href='https://app.termly.io/document/privacy-policy/bcd4c128-be64-4a4f-80c2-e444169dc8c3'>Privacy Policy</Link></p>
            <p><Link href="#">Support</Link></p>
        </Paper>
    
        <Paper elevation={0}>
            <Typography variant={"h6"}>Plans</Typography>
            <p><Link href="#">Supporter</Link></p>
            <p><Link href="#">Professional</Link></p>
            <p><Link href="#">Institutional</Link></p>
        </Paper>
        
        </Stack>
    )
}

export default Footer;
