import { Bubble } from 'react-chartjs-2';
import { useEffect } from 'react'

export default function BubbleChart({items, ...props}) {
    
  const maxRadius = 20
  const normalizer = 250
  const colorUnit = 10
  
  
  useEffect(() => {

  }, [items])
  
  const handleClick = (element) => {
    if (element.length>0) {
        window.open(items[element[0].datasetIndex].DocumentIdentifier, '_blank')
    }
  }
  

  const options = {
    plugins: {
      legend: {display: false},
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const xyr = tooltipItem.dataset.data[0]
            let wc = xyr.r * normalizer
            if (xyr.r>=maxRadius) {
                wc = '>= ' + wc
            }
            
            return [tooltipItem.dataset.label, "Negative Tone: " + xyr.x.toLocaleString(), "Positive Tone: " + xyr.y.toLocaleString(), "Word Count: " + wc] ;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: '#adb5bd'
        },
        title: {
         text: 'Negative Tone',
         display: true,
        }
      },
      y: {
        grid: {
          display: false,
          borderColor: '#adb5bd'
        },
        title: {
          text: 'Positive Tone',
          display: true,
        }
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    onClick: function (event, element) {
        handleClick(element)
    }
  }
  
  if (items.length>0) {
    const dataset = []
    for (var i in items) {
      dataset.push({
        label: items[i]["SourceCommonName"],
        data: [
            {
              x: items[i].NegativeTone, 
              y: items[i].PositiveTone, 
              r: Math.min(items[i].WordCount/normalizer, maxRadius)
            }
        ],
        backgroundColor: 'rgba(30,' + Math.min(255,Math.ceil(items[i].Polarity*colorUnit)) + ', 255, 0.8)',
        borderColor: 'rgba(30,' + Math.min(255,Math.ceil(items[i].Polarity*colorUnit)) + ', 255, 0.8)',
      })

    }
    const data = {
        datasets: dataset
    }

    return (
      <Bubble options={options} data={data}/>
    )
  } else {
    return (
      <></>
    )
  }
    
}
