import { Paper,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Button,
	Typography,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { put, post } from "./Functions";
import { useEffect, useState } from "react"

const stripe = require("stripe")(process.env.REACT_APP_STRIPE_API_LIVE)


function Catalog({item, activePlan, onCheckoutSuccess, ...props}) {	
	let navigate = useNavigate()
	
	const [prod, setProd] = useState(null)
  const user = JSON.parse(localStorage.getItem('user'))
  
  useEffect(() => {
  	stripe.products.retrieve(item.product)
  	.then(res => {
  		setProd(res)
  	})
  }, [item])
  
	const handleCheckout = () => {
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		stripe.checkout.sessions.create({
				line_items: [
					{ price: item.id, quantity: 1 },
				],
				mode: 'subscription',
				customer_email: user.email,
				success_url: "https://esg.cafe/user/stripe"
										+ "?email=" + user.email
										+ "&token=" + token
		}).then(res => {
			window.location.replace(res.url)
			post("/user/stripe",
				{ id: res.id },
				token
			).then(res => {
				
			})
		})
		
	}
	
	useEffect(() => {
		
	}, [item, activePlan])
	
	return (
		<Paper sx={{ width: "100%", 
				maxWidth: 400,
				pb: 2,
				pt: 2,
				textAlign: "center",
				}}
			elevation={1}
			>
		<Typography variant="h5" sx={{ textAlign: "center", pb: 2 }}> 
			{prod 
				? prod.name 
				: ""
			}
		</Typography>
		<Typography variant="caption"> 
			{prod 
				? prod.description 
				: ""
			}
		</Typography>
		<List 
			disablePadding={true} 
			dense
			>
		{prod && prod.metadata.features
			? prod.metadata
				.features.split(",")
				.map(f => (
					<ListItem sx={{ pb: 0, pt: 0 }} key={f}>
						<ListItemIcon ><CheckIcon /></ListItemIcon>
						<ListItemText>{f}</ListItemText>
					</ListItem>
				))
			: ""
		}
		</List>
		<Typography variant="h5" sx={{ textAlign: "center" }}> 
			{item ? (item.unit_amount / 100) : null} €
		</Typography>
		
		<Typography sx={{ textAlign: "center", marginBottom: "10pt" }}> 
			per&nbsp;{item.recurring.interval}
		</Typography>


		{activePlan
			? <Button variant="outlined"
					startIcon={<CheckIcon />}
					>
					Your current plan.
				</Button>
			: <Button variant="contained" 
					onClick={() => handleCheckout()}
					>
					Select
				</Button>
			
		}
		
		</Paper>
		
	)
}

export default Catalog;
